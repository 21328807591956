import { Controller } from "@hotwired/stimulus";


// Connects to data-controller="hubspot-embed"
export default class extends Controller {
  static values = { formId: String, target: String, portalId: String, region: String };

  connect() {
    window.hbspt.forms.create({
      region: this.regionValue,
      portalId: this.portalIdValue,
      formId: this.formIdValue,
      target: this.targetValue,
    });
  }
}
