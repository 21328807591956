import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {


    const swiperOptions = this.optionsValue;

    this.swiper = new Swiper(this.element, swiperOptions);
  }

  disconnect() {
    if (this.swiper) this.swiper.destroy();
  }
}
