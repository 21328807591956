import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";
import getScrollParent from "../utils/scroll-parent";

// Connects to data-controller="ai-chat"
export default class extends Controller {
  static targets = [
    "prompt",
    "conversation",
    "userMessageTemplate",
    "assistantMessageTemplate",
    "warningTemplate",
    "message",
  ];

  static values = {
    conversationStarter: String,
  };

  initialize() {
    const debounce = require("lodash/debounce");

    this.scrollToBottom = debounce(this.scrollToBottom, 100).bind(this);
    this.generationInProgress = false;
    this.handleScrollUp = this.handleScrollUp.bind(this);
    this.scrollOnCompletion = true;
    this.scrollOnCompletionBuffer = 100; // This is the threshold value to counter loader flickering
  }

  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  conversationTargetConnected(element) {
    const message = element.dataset.conversationStarter;
    if (message?.trim()) {
      this.promptTarget.value = message.trim();
      this.generateResponse(new Event("submit"));
    }

    this.scrollParentElement = getScrollParent(element);
  }

  messageTargetConnected(element) {
    this.scrollToBottom();
  }

  generateResponse(event) {
    event.preventDefault();

    if (this.generationInProgress) return;
    if (!this.promptTarget.value?.trim()) return;

    this.markGenerationInProgress();

    this.promptTarget.value = this.promptTarget.value.trim();

    this.appendUserMessage(this.promptTarget.value);
    this.appendAssistantMessage();
    this.hideWarning();

    this.setupEventSource();

    this.promptTarget.value = "";
    // Reset the height changed by autogrow-textarea-controller
    autosize.update(this.promptTarget);
  }

  regerateResponse(event) {
    event.preventDefault();

    this.markGenerationInProgress();

    this.conversationTarget.children[
      this.conversationTarget.children.length - 1
    ].remove();
    this.appendAssistantMessage();

    this.setupEventSource(true);
  }

  appendUserMessage(message) {
    const userMessage = this.userMessageTemplateTarget.content.cloneNode(true);
    userMessage.querySelector(
      '[data-ai-chat-target="message"]'
    ).dataset.markedSourceValue = message;
    this.conversationTarget.appendChild(userMessage);
  }

  appendAssistantMessage() {
    const assistantMessage =
      this.assistantMessageTemplateTarget.content.cloneNode(true);

    this.currentStreamingMarkedContent = assistantMessage.querySelector(
      '[data-controller="marked"]'
    );

    this.conversationTarget.appendChild(assistantMessage);
  }

  setupEventSource(regeneration = false) {
    const eventSourceUrl = regeneration
      ? this.promptTarget.dataset.regenerationUrl
      : encodeURI(
          `${this.promptTarget.dataset.streamUrl}?prompt=${this.promptTarget.value}`
        );

    if (this.eventSource) this.eventSource.close();
    this.eventSource = new EventSource(eventSourceUrl);

    this.eventSource.addEventListener(
      "message",
      this.handleStreamMessage.bind(this)
    );
    this.eventSource.addEventListener(
      "error",
      this.handleStreamError.bind(this)
    );
  }

  handleStreamMessage(event) {
    const warning = JSON.parse(event.data).warning;
    if (warning) {
      this.showWarning(warning);
      return;
    }

    const reject = JSON.parse(event.data).reject;
    if (reject) {
      this.rejectUserMessage();
      return;
    }

    const message = JSON.parse(event.data).message;
    this.currentStreamingMarkedContent.dataset.markedSourceValue ||= "";
    this.currentStreamingMarkedContent.dataset.markedSourceValue += message;

    // TODO: Handle the case when code_interpreter is processing data. May be show loader.

    this.scrollToBottom();
  }

  handleStreamError(event) {
    if (event.eventPhase === EventSource.CLOSED) {
      this.eventSource.close();
      this.updateAssistantMessage();
    }
  }

  showWarning(message) {
    this.prepareWarningElement();

    this.warningElement.querySelector("[data-ai-chat-warning-text]").innerHTML = message;
    this.warningElement.classList.add("flex");
    this.warningElement.classList.remove("hidden");
  }

  hideWarning() {
    if (!this.warningElement) return;

    this.warningElement.classList.add("hidden");
    this.warningElement.classList.remove("flex");
  }

  rejectUserMessage() {
    // Remove the latest assistant message
    this.conversationTarget.lastElementChild.remove();

    // Update the prompt value with the latest user message and then delete the latest user message
    const latestUserMessage = this.conversationTarget.lastElementChild;
    this.promptTarget.value = latestUserMessage.querySelector(
      "[data-marked-source-value]"
    )?.dataset?.markedSourceValue;
    latestUserMessage.remove();

    this.scrollToBottom();
  }

  prepareWarningElement() {
    if (this.warningElement) return;

    this.warningElement = this.warningTemplateTarget.content.firstElementChild.cloneNode(true);
    this.promptTarget.insertAdjacentElement("beforebegin", this.warningElement);

    this.warningElement
      .querySelector("button")
      .addEventListener("click", (ev) => {
        ev.preventDefault();

        this.hideWarning();
      });

    this.hideWarning();
  }

  updateAssistantMessage() {
    const message = this.currentStreamingMarkedContent.dataset.markedSourceValue;
    if (!message.trim()) {
      // Show error message, if something went wrong
      this.currentStreamingMarkedContent.innerHTML =
        "Something went wrong. Please try again.";
    } else {
      this.currentStreamingMarkedContent
        .closest(".group")
        .classList.remove("generating");
    }
    this.markGenerationComplete();
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (!this.scrollOnCompletion) return;

    this.element.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }

  handleScrollUp() {
    const { scrollTop, scrollHeight, clientHeight } = this.scrollParentElement;
    const isAtBottom =
      scrollHeight - scrollTop <= clientHeight + this.scrollOnCompletionBuffer;

    if (isAtBottom) {
      this.scrollOnCompletion = true;
    } else {
      this.scrollOnCompletion = false;
    }
  }

  markGenerationInProgress() {
    this.generationInProgress = true;
    this.scrollOnCompletion = true;
    this.promptTarget.form.querySelector("button[type=submit]").disabled = true;
    this.scrollParentElement?.addEventListener("scroll", this.handleScrollUp);
  }

  markGenerationComplete() {
    this.generationInProgress = false;
    this.promptTarget.form.querySelector(
      "button[type=submit]"
    ).disabled = false;
    this.scrollParentElement?.removeEventListener("scroll", this.handleScrollUp);
  }
}
