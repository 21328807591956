// Example usage:
// <div data-controller="tooltip" data-tooltip-content-value="Hello world"></div>
//
// In HAML:
// tag.div '', data: { controller: 'tooltip', tooltip: { content_value: 'Hello, World' } }
// tag.div '', data: { controller: 'tooltip', tooltip: { template_value: 'template_ele_id', options_value: { allowHTML: true }.to_json }
//
// In ERB:
// ERB fails to unfurl the nested data attributes. So, we need to do the heavy lifting.
// tag.div '', data: { controller: 'tooltip', tooltip_content_value: 'Hello, World' }
// tag.div '', data: { controller: 'tooltip', tooltip_template_value: 'template_ele_id', tooltip_options_value: { allowHTML: true }.to_json }

import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
    options: {
      Object,
      default: { allowHTML: true, interactive: true, maxWidth: 250 },
    },
    template: String,
  };

  connect() {
    let options = this.optionsValue || {};
    if (this.hasContentValue) {
      options["content"] = this.contentValue;
    }
    if (this.hasTemplateValue) {
      const template = document.getElementById(this.templateValue);
      options["content"] = template.innerHTML;
    }
    if (options["content"] && options["content"].trim() !== "") {
      this.tippy = tippy(this.element, options);
    }
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }
}
