import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";

// Connects to data-controller="autogrow-textarea"
export default class extends Controller {
  connect() {
    autosize(this.element);
    this.element.addEventListener("focus", () => autosize.update(this.element));
  }
}
