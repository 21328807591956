import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";
import $ from "jquery";

export default class extends Controller {
  static values = { key: String };
  static targets = ["spinner", "button"];

  async connect() {
    this.stripe = await loadStripe(this.keyValue);
    this.resetSpinner();

    window.addEventListener("pageshow", this.resetSpinner.bind(this));
    // document.addEventListener("turbo:load", this.resetSpinner.bind(this));
  }

  disconnect() {
    window.removeEventListener("pageshow", this.resetSpinner.bind(this));
    // document.removeEventListener("turbo:load", this.resetSpinner.bind(this));
  }

  async handlePayment(event) {
    event.preventDefault();

    this.showSpinner();

    try {
      const session = await (
        await fetch(`/payments/stripe`, {
          method: "POST",
          headers: {
            "X-CSRF-Token": $("meta[name='csrf-token']").attr("content"),
          },
        })
      ).json();

      const response = await this.stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (response.error) {
        toastr.error(response.error.message);
      }
    } catch (_) {
      toastr.error(
        "Couldn't connect to payments provider. Please try again after some time"
      );
    }

    this.resetSpinner();
  }

  resetSpinner() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false;
    }
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.className = "fi-icon-arrow-right";
    }
  }

  showSpinner() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true;
    }
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.className = "fi-icon-spinner animate-spin";
    }
  }
}
