import { Controller } from "@hotwired/stimulus";
import DataTable from "datatables.net-dt";

// Connects to data-controller="data-table"
{
  /* <div
  data-controller="data-table"
  data-page="1"
  data-responsive-value="false"
  data-paging-value="true"
  data-scroll-collapse-value="false"
  data-info-value="true"
  data-order-value='["column1", "asc"]'
  id="page_breakdown_1"
> */
}

export default class extends Controller {
  static values = {
    responsive: Boolean,
    paging: Boolean,
    scrollCollapse: Boolean,
    info: Boolean,
    order: Array,
    page: Number,
  };

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (!DataTable.fnIsDataTable(this.element)) {
      const defaultOptions = {
        responsive: true,
        paging: false,
        scrollCollapse: true,
        info: false,
        order: [],
      };

      const options = Object.assign(defaultOptions, {
        responsive: this.responsiveValue,
        paging: this.pagingValue,
        scrollCollapse: this.scrollCollapseValue,
        info: this.infoValue,
        order: this.orderValue,
        pageLength: this.pageValue,
      });

      new DataTable(this.element, options);
    }
  }

  disconnect() {
    // This will remove all event listeners and dom elements added by the datatable
    if (this.element.DataTable) {
      this.element.DataTable().destroy();
    }
  }
}
