// Example usage:
// <div data-controller="empty"></div>
//

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "placeholder"];

  connect() {
    this.check();
    this.placeholderTarget.removeAttribute("x-cloak");

    this.observer = new MutationObserver(this.check.bind(this));
    this.observer.observe(this.contentTarget, { childList: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  check() {
    if (this.contentTarget.innerHTML.trim() == "") {
      this.contentTarget.classList.add("hidden");
      this.placeholderTarget.classList.remove("hidden");
    } else {
      this.contentTarget.classList.remove("hidden");
      this.placeholderTarget.classList.add("hidden");
    }
  }
}
