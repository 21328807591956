import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal-content"
export default class extends Controller {
  static values = {
    title: String,
    description: String,
  };

  connect() {
    if (this.element.hasAttribute("data-connect-modal")) {
      if (this.element.innerHTML?.trim()) {
        this.modalController().populate(
          this.titleValue,
          this.descriptionValue,
          this.element.innerHTML
        );
        this.modalController().open();
      } else {
        this.modalController().close();
      }
      setTimeout(this.remove.bind(this), 1000);
    }
  }

  modalController() {
    this.modalControllerInstance ||=
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller="modal"]'),
        "modal"
      );

    return this.modalControllerInstance;
  }

  remove() {
    this.element.remove();
  }

  open(event) {
    event.preventDefault();
    const body = this.element.querySelector(
      "[data-modal-content-body-value]"
    )?.innerHTML;
    if (body) {
      this.modalController().populate(
        this.titleValue,
        this.descriptionValue,
        body.trim()
      );
      this.modalController().open();
    }
  }
}
