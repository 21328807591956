import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ["changessaved", "editor", "savebutton"];
  isRequestInProgress = false;

  connect() {
    this.form = this.element.closest("form");
    // this.formData = new FormData(this.form);

    this.url = this.form.dataset.url;
    const autosave_delay = this.form.dataset.autosaveDelay || 1000;
    this.autosaveDelayAsInt = parseInt(autosave_delay, 10);

    const requestToDelay = () => this.sendRequest();
    this.debouncedRequest = debounce(requestToDelay, this.autosaveDelayAsInt);

    this.editorTarget.addEventListener("keyup", this.debouncedRequest);
    // this.editorTarget.addEventListener("blur", this.save.bind(this));
  }

  save() {
    this.sendRequest();
  }

  sendRequest() {
    if (this.isRequestInProgress) {
      this.debouncedRequest.cancel();
      return;
    }

    this.isRequestInProgress = true;

    this.savebuttonTarget.disabled = true;
    const originalButtonText = this.savebuttonTarget.innerHTML;
    this.savebuttonTarget.innerHTML = "Saving...";
    this.formData = new FormData(this.form);

    fetch(this.url, {
      method: "PATCH",
      body: this.formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        Accept: "text/vnd.turbo-stream.html",
      },
      credentials: "same-origin",
    }).then((response) => {
      response.text().then((_html) => {
        const changesSaved = this.changessavedTarget;
        changesSaved.classList.remove("invisible");
        this.savebuttonTarget.disabled = false;
        this.savebuttonTarget.innerHTML = originalButtonText;
        this.isRequestInProgress = false;
        setTimeout(() => {
          changesSaved.classList.add("invisible");
        }, 1000);
      });
    });
  }

  disconnect() {
    this.editorTarget.removeEventListener("keyup", this.debouncedRequest);
    // this.editorTarget.removeEventListener("blur", this.save.bind(this));
  }
}
