import { Controller } from "@hotwired/stimulus";

// connect to data-controller="active-nav-scroller"
export default class extends Controller {
  static targets = ["section"];
  static values = { activeClass: String };

  connect() {
    this.navItems = document.querySelectorAll(".scroller-nav");
    let activeSection = this.sectionTargets[0].dataset.categoryId;
    this.updateActiveClass(activeSection);

    const observerOptions = {
      root: document.querySelector(".scroller"),
      rootMargin: "-50% 0px -98%",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let activeSection = entry.target.dataset.categoryId;
          this.updateActiveClass(activeSection);
        }
      });
    }, observerOptions);

    this.sectionTargets.forEach((section) => {
      observer.observe(section);
    });
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  onHashChange() {
    this.isScrollingFromHashChange = true;
    setTimeout(() => {
      this.isScrollingFromHashChange = false;
    }, 1000);
  }

  updateActiveClass(activeSectionId) {
    this.navItems.forEach((section) => {
      const sectionId = section.dataset.categoryId;
      if (sectionId === activeSectionId) {
        section.classList.add(this.activeClassValue);
      } else {
        section.classList.remove(this.activeClassValue);
      }
    });
  }
}
