import { Controller } from "@hotwired/stimulus";
import ahoy from "../ahoy.js";
import Vimeo from "@vimeo/player";

// Connects to data-controller="ahoy-analytics"
// Example usage:
// <div data-controller="ahoy-analytics" data-ahoy-analytics-title-value="Event Name" data-ahoy-analytics-props-value=""></div>
//
// In HAML:
// tag.div '', data: { controller: 'ahoy-analytics', ahoy_analytics: { title_value: 'Event Name', props_value: JSON.parse(props_hash) } }
//
// In ERB:
// ERB fails to unfurl the nested data attributes. So, we need to do the heavy lifting.
// tag.div '', data: { controller: 'ahoy-analytics', ahoy_analytics_title_value: 'Event Name', ahoy_analytics_props_value: JSON.parse(props_hash) }

export default class extends Controller {
  static values = {
    title: String,
    props: Object,
  };

  connect() {
    if (this.element.tagName == "A" || this.element.tagName == "BUTTON") {
      this.trackClick(this.element);
    } else if (this.element.tagName == "IFRAME") {
      this.trackVimeoPlayer(this.element);
    }
  }

  disconnect() {
    this.element.removeEventListener("click", this.trackClick.bind(this));
  }

  trackClick(element) {
    element.addEventListener("click", this.track.bind(this));
  }

  track() {
    ahoy.track(this.titleValue, this.propsValue);
  }

  trackVimeoPlayer(element) {
    const player = new Vimeo(element);
    let playTracked = false; // Track play event only once
    let videoDuration = 0;
    let lastPercentageSent = 0;
    let videoId;
    let timeNow;

    player.on("play", () => {
      if (!playTracked) {
        player.getVideoId().then((id) => {
          videoId = id;
          ahoy.track("Play", { videoId: videoId, ...this.propsValue });
          playTracked = true;
        });

        player.getDuration().then((duration) => {
          videoDuration = duration;

          player.on("timeupdate", () => {
            player.getCurrentTime().then((currentTime) => {
              timeNow = currentTime;
              const percentageWatched = (timeNow / duration) * 100;
              const roundedPercentage = Math.round(percentageWatched);

              if (
                roundedPercentage % 20 == 0 &&
                roundedPercentage !== lastPercentageSent
              ) {
                lastPercentageSent = roundedPercentage;
                ahoy.track("Play Progress", {
                  videoId: videoId,
                  ...this.propsValue,
                  progress: roundedPercentage,
                });
              }
            });
          });
        });
      }
    });
  }
}
