import consumer from "./consumer";
import getCookieValue from "../v4/utils/get-cookie-value";

const currentPath = window.location.pathname;

if (initiateActionCable(currentPath)) {
  const clientUuid = getCookieValue("client_uuid");

  let currentSemesterId;

  consumer.subscriptions.create(
    { channel: "SemesterSessionChannel", client_uuid: clientUuid },
    {
      // Called when the subscription is ready for use on the server
      connected() {
        currentSemesterId = parseInt(
          document.getElementById("sidebar").dataset.semesterId
        );
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        let modal = document.querySelector(".modal#semester-session-alert");
        let infoText = modal.querySelector("#info-text");

        if (currentSemesterId != data.semester_id) {
          window.closeModal();

          infoText.innerHTML =
            "The semester has been switched in another tab. Please refresh the page.";

          modal.classList.remove("hidden");
        } else if (data.semester_settings_changed) {
          window.closeModal();

          infoText.innerHTML =
            "Semester Settings have been changed in another tab. Please refresh the page.";

          modal.classList.remove("hidden");
        } else if (!modal.classList.contains("hidden")) {
          modal.classList.add("hidden");
        }
      },
    }
  );
}

function initiateActionCable(path) {
  const whitelist = [
    "/admin/courses",
    "/admin/enrollment",
    "/admin/home",
    "/admin/mentors",
    "/admin/partners",
    "/admin/reviews",
    "/admin/semester",
    "/admin/teams",
    "/admin/operating",
  ];
  return whitelist.includes(path);
}
