import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "title", "body", "description"];

  connect() {
    this.opened = false;
    this.modalTarget.addEventListener("mousedown", (ev) => {
      if (ev.target === ev.currentTarget) this.close.bind(this)();
    });
  }

  disconnect() {
    this.close();
  }

  populate(title, description, body) {
    this.titleTarget.innerHTML = title;
    this.descriptionTarget.innerHTML = description;
    this.bodyTarget.innerHTML = body;

    if (!this.titleTarget.textContent.trim()) {
      this.titleTarget.parentElement.parentElement.style.display = "none";
    }
  }

  open() {
    if (this.opened) return;

    this.show();
    document.querySelector("body").style.overflowY = "hidden";
    window.closeSlideover = this.close.bind(this);
    this.opened = true;
  }

  close(event) {
    event && event.preventDefault();
    if (!this.opened) return;

    document.querySelector("body").style.overflowY = "auto";
    this.modalTarget.scrollTop = 0;
    setTimeout(this.hide.bind(this), 1);
    this.opened = false;
  }

  show() {
    this.element.classList.remove("hidden");
  }

  hide() {
    this.element.classList.add("hidden");
  }
}
