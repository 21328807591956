import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    offerCutoffTime: Number,
    testCompletedTime: Number,
  };

  static targets = ["modal"];

  connect() {
    window.addEventListener("pageshow", this.initializeTimer.bind(this));
    // document.addEventListener("turbo:load", this.initializeTimer.bind(this));
    this.initializeTimer();
  }

  initializeTimer() {
    this.destroyInterval();

    const currentTime = Math.floor(Date.now() / 1000);
    const countdownTime =
      this.testCompletedTimeValue + this.offerCutoffTimeValue;
    let remainingTime = countdownTime - currentTime;

    if (remainingTime > 0) {
      this.updateCountdownTimer(remainingTime);
      this.interval = setInterval(() => {
        remainingTime--;
        this.updateCountdownTimer(remainingTime);
        if (remainingTime <= 0) {
          this.destroyInterval();
          if (this.modalTarget) {
            this.modalTarget.classList.remove("hidden");
          }
        }
      }, 1000);
    } else {
      this.updateCountdownTimer(0);
    }
  }

  updateCountdownTimer(remainingTime) {
    const hours = String(Math.floor(remainingTime / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((remainingTime % 3600) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(remainingTime % 60).padStart(2, "0");

    if (this.element.querySelector("[data-countdown-timer-seconds]")) {
      this.element.querySelector("[data-countdown-timer-hours]").textContent =
        hours;
      this.element.querySelector("[data-countdown-timer-minutes]").textContent =
        minutes;
      this.element.querySelector("[data-countdown-timer-seconds]").textContent =
        seconds;
    } else {
      this.destroyInterval();
    }
  }

  destroyInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  disconnect() {
    this.destroyInterval();
    window.removeEventListener("pageshow", this.initializeTimer.bind(this));
    // document.removeEventListener("turbo:load", this.initializeTimer.bind(this));
  }
}
