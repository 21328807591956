// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

const createConfirmModal = (element) => {
  var id = "confirm-modal-" + String(Math.random()).slice(2, -1);
  var confirm = element.dataset.confirm;

  var confirmBody = element.dataset.confirmBody;
  confirmBody = confirmBody
    ? `
    <div class="mt-2">
      <p class="text-sm text-graytw-500">${confirmBody}</p>
    </div>
  `
    : "";

  // var content = `
  //   <div id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table" style="background-color: rgba(0, 0, 0, 0.8);">
  //     <div class="table-cell align-middle">

  //       <div class="bg-white mx-auto rounded shadow p-8 max-w-sm">
  //         <h4>${confirm}</h4>

  //         <div class="flex justify-end items-center flex-wrap mt-6">
  //           <button data-behavior="cancel" class="btn btn-light-gray mr-2">Cancel</button>
  //           <button data-behavior="commit" class="btn btn-danger focus:outline-none">Confirm</button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // `

  var content = `
    <div id="${id}" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-graytw-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation-triangle -->
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg font-medium leading-6 text-graytw-900">${confirm}</h3>

                ${confirmBody}
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
              <button type="button" data-behavior="commit" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none sm:w-auto sm:text-sm">Confirm</button>
              <button type="button" data-behavior="cancel" class="mt-3 inline-flex w-full justify-center rounded-md border border-graytw-300 bg-white px-4 py-2 text-base font-medium text-graytw-700 shadow-sm hover:bg-graytw-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  element.insertAdjacentHTML("afterend", content);

  var modal = element.nextElementSibling;
  element.dataset.confirmModal = `#${id}`;

  modal.addEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      element.removeAttribute("data-confirm-modal");
      modal.remove();
    }
  });

  modal
    .querySelector("[data-behavior='cancel']")
    .addEventListener("click", (event) => {
      event.preventDefault();
      element.removeAttribute("data-confirm-modal");
      modal.remove();
    });
  modal
    .querySelector("[data-behavior='commit']")
    .addEventListener("click", (event) => {
      event.preventDefault();

      // Allow the confirm to go through
      Rails.confirm = () => {
        return true;
      };

      // Click the link again
      element.click();

      // Remove the confirm attribute and modal
      element.removeAttribute("data-confirm-modal");
      Rails.confirm = old_confirm;

      modal.remove();
    });

  modal.querySelector("[data-behavior='commit']").focus();
  return modal;
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

  // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
