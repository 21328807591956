// Example usage:
// <div data-controller="navigation" data-navigation-src-value="/some/path"></div>
//
// In HAML:
// tag.div '', data: { controller: 'navigation', navigation: { src_value: '/some/path' } }
//
// In ERB:
// ERB fails to unfurl the nested data attributes. So, we need to do the heavy lifting.
// tag.div '', data: { controller: 'navigation', navigation_src_value: '/some/path' }

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    src: String,
  };

  connect() {
    this.element.addEventListener("click", this.navigate.bind(this));
  }
  disconnect() {
    this.element.removeEventListener("click", this.navigate.bind(this));
  }

  navigate() {
    fetch(this.srcValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
