import { render, register } from "timeago.js";

const localeFunc = (number, index, totalSec) => {
  // number: the timeago / timein number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  return [
    ["just now", "right now"],
    ["just now", "right now"],
    ["1 min ago", "in 1 min"],
    ["%s mins ago", "in %s mins"],
    ["1 hour ago", "in 1 hour"],
    ["%s hours ago", "in %s hours"],
    ["1 day ago", "in 1 day"],
    ["%s days ago", "in %s days"],
    ["1 week ago", "in 1 week"],
    ["%s weeks ago", "in %s weeks"],
    ["1 month ago", "in 1 month"],
    ["%s months ago", "in %s months"],
    ["1 year ago", "in 1 year"],
    ["%s years ago", "in %s years"],
  ][index];
};
// register your locale with timeago
register("fi-locale", localeFunc);

// use it
// format('2016-06-12', 'my-locale');

const renderTimeAgo = () => {
  if (document.querySelectorAll("time[datetime]").length > 0)
    render(document.querySelectorAll("time[datetime]"), "fi-locale");
};

document.addEventListener("turbo:load", () => {
  renderTimeAgo();
});

window.renderTimeAgo = renderTimeAgo;
