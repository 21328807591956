import { Controller } from "@hotwired/stimulus";
import linkify from "linkifyjs/jquery";
import $ from "jquery";

// Connects to data-controller="linkify"
export default class extends Controller {
  connect() {
    linkify($);
    this.linkifyLinks();
  }

  linkifyLinks() {
    $(".turbo-linkify", this.element).linkify({
      target: "_blank",
    });
  }
}
