import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autosubmitting-textarea"
//
// Hitting enter key submits the form
// Hitting shift+enter inserts a newline
//
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.element.form.requestSubmit();
    }
  }
}
