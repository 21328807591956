import { Controller } from "@hotwired/stimulus";
import "select2";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    const $ = window.$;
    $(this.selectTarget).select2({
      multiple: this.selectTarget.multiple,
    });

    $(this.selectTarget).on("select2:open", () => {
      setTimeout(() => {
        this.selectTarget.focus();
      }, 0);
    });
  }

  disconnect() {
    // window.$(this.selectTarget).select2("destroy");
  }
}
