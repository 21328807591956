window.turboPageScrollPreserveValue = null;

window.preserveScrollOnTurboLoad = () => {
  window.turboPageScrollPreserveValue = window.pageYOffset;
};

addEventListener("turbo:load", () => {
  if (window.turboPageScrollPreserveValue) {
    setTimeout(() => {
      window.scrollTo(0, window.turboPageScrollPreserveValue);
      window.turboPageScrollPreserveValue = null;
    }, 0);
  } else {
    if (location.hash) {
      var element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }
});
