import { Controller } from "@hotwired/stimulus";
import { marked } from "marked";

// Connects to data-controller="marked"
export default class extends Controller {
  static values = {
    source: String,
  };

  connect() {
    this.parse();
  }

  parse() {
    if (this.sourceValue)
      this.element.innerHTML = marked.parse(this.sourceValue, { breaks: true });
  }

  sourceValueChanged() {
    this.parse();
  }
}
