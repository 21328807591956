import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sidekick-chat"
export default class extends Controller {
  static targets = [
    "resources", // The resources container
    "chat", // The chat container
    "reset", // The button that starts a new chat
    "popover", // The button that opens the chat in a new window
    "lazyLoader", // The hidden link that triggers the lazy loading of the chat
    "title", // The title element within sidekick chat
    "conversation", // The container for the conversation,
    "loaderTemplate", // The template for the loader
  ];

  connect() {
    this.hide();
  }

  show(ev) {
    this.resetTarget.href = ev.params.resetUrl;
    this.popoverTarget.href = ev.params.popoverUrl;
    this.lazyLoaderTarget.href = ev.params.loadUrl;
    this.titleTarget.textContent = ev.params.title;
    this.conversationTarget.replaceChildren(
      this.loaderTemplateTarget.content.firstElementChild.cloneNode(true)
    );
    this.lazyLoaderTarget.click();
    this.resourcesTarget.classList.remove("block");
    this.resourcesTarget.classList.add("hidden");
    this.chatTarget.classList.remove("hidden");
    this.chatTarget.classList.add("block");
  }

  hide() {
    this.resourcesTarget.classList.remove("hidden");
    this.resourcesTarget.classList.add("block");
    this.chatTarget.classList.remove("block");
    this.chatTarget.classList.add("hidden");
  }
}
