import Trix from "trix";
import "@rails/actiontext";
import { get } from "@rails/request.js";

Trix.config.toolbar.getDefaultHTML = toolbarDefaultContent;

// Set the default toolbar content when trix editor is embedded in a lzy loaded turbo frame.
// Ref: https://dev.to/konnorrogers/modifying-the-default-toolbar-in-trix-411b
//
// document.addEventListener('trix-initialize', updateToolbars, { once: true });

// function updateToolbars(event) {
//   const toolbars = document.querySelectorAll("trix-toolbar");
//   const html = Trix.config.toolbar.getDefaultHTML();
//   toolbars.forEach((toolbar) => {
//     toolbar.innerHTML = html;
//   }
// }

// Trix.config.textAttributes.inlineCode = {
//   tagName: "code",
//   inheritable: true,
// };

// Trix.trixAttributes.underline = {
//   styleProperty: "text-decoration",
// }

class EmbedController {
  constructor(element) {
    this.patterns = undefined;
    this.element = element;
    this.editor = element.editor;
    this.toolbar = element.toolbarElement;

    this.injectHTML();

    this.hrefElement = this.toolbar.querySelector(
      "[data-trix-input][name='href']"
    );
    this.embedContainerElement = this.toolbar.querySelector(
      "[data-behavior='embed_container']"
    );
    this.embedElement = this.toolbar.querySelector(
      "[data-behavior='embed_url']"
    );

    this.reset();
    this.installEventHandlers();
  }

  injectHTML() {
    this.toolbar.querySelector('[data-trix-dialog="href"]').insertAdjacentHTML(
      "beforeend",
      `
        <div data-behavior="embed_container" class="mt-3">
          <div class="link_to_embed link_to_embed--new">
            Would you like to embed media from this site?
            <input class="ml-2 rounded bg-graytw-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-graytw-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-graytw-600" type="button" data-behavior="embed_url" value="Yes, embed it">
          </div>
        </div>
      `
    );
  }

  installEventHandlers() {
    this.hrefElement.addEventListener("input", this.didInput.bind(this));
    this.hrefElement.addEventListener("focusin", this.didInput.bind(this));
    this.embedElement.addEventListener("click", this.embed.bind(this));
  }

  didInput(event) {
    let value = event.target.value.trim();

    // Load patterns from server so we can dynamically update them
    if (this.patterns === undefined) {
      this.loadPatterns(value);

      // When patterns are loaded, we can just fetch the embed code
    } else if (this.match(value)) {
      this.fetch(value);

      // No embed code, just reset the form
    } else {
      this.reset();
    }
  }

  async loadPatterns(value) {
    const response = await get("/action_text/embeds/patterns.json", {
      responseKind: "json",
    });
    if (response.ok) {
      const patterns = await response.json;
      this.patterns = patterns.map(
        (pattern) => new RegExp(pattern.source, pattern.options)
      );
      if (this.match(value)) {
        this.fetch(value);
      }
    }
  }

  // Checks if a url matches an embed code format
  match(value) {
    return this.patterns.some((regex) => regex.test(value));
  }

  fetch(value) {
    Rails.ajax({
      url: `/action_text/embeds?id=${encodeURIComponent(value)}`,
      type: "post",
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this),
    });
  }

  embed(event) {
    if (this.currentEmbed == null) {
      return;
    }

    let attachment = new Trix.Attachment(this.currentEmbed);
    this.editor.insertAttachment(attachment);
    this.element.focus();
  }

  showEmbed(embed) {
    this.currentEmbed = embed;
    this.embedContainerElement.style.display = "block";
  }

  reset() {
    this.embedContainerElement.style.display = "none";
    this.currentEmbed = null;
  }
}

class InlineCode {
  constructor(element) {
    this.element = element;
    this.editor = element.editor;
    this.toolbar = element.toolbarElement;

    this.installEventHandlers();
  }

  installEventHandlers() {
    const blockCodeButton = this.toolbar.querySelector(
      "[data-trix-attribute=code]"
    );
    const inlineCodeButton = blockCodeButton.cloneNode(true);

    inlineCodeButton.hidden = true;
    inlineCodeButton.dataset.trixAttribute = "inlineCode";
    blockCodeButton.insertAdjacentElement("afterend", inlineCodeButton);

    this.element.addEventListener("trix-selection-change", (_) => {
      const type = this.getCodeFormattingType();
      blockCodeButton.hidden = type == "inline";
      inlineCodeButton.hidden = type == "block";
    });
  }

  getCodeFormattingType() {
    if (this.editor.attributeIsActive("code")) return "block";
    if (this.editor.attributeIsActive("inlineCode")) return "inline";

    const range = this.editor.getSelectedRange();
    if (range[0] == range[1]) return "block";

    const text = this.editor.getSelectedDocument().toString().trim();
    return /\n/.test(text) ? "block" : "inline";
  }
}

class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }
}

function innerHTML(alignment) {
  return `
    <slot></slot>
  `;
}

export class AlignLeftElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML("left");
  }
}

export class AlignCenterElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML("center");
  }
}

window.customElements.define("align-left", AlignLeftElement);
window.customElements.define("align-center", AlignCenterElement);

Trix.config.blockAttributes.alignLeft = {
  tagName: "align-left",
  parse: true,
  nestable: false,
  exclusive: false,
};

Trix.config.blockAttributes.alignCenter = {
  tagName: "align-center",
  parse: true,
  nestable: false,
  exclusive: false,
};

Trix.config.blockAttributes.hTwo = {
  tagName: "h2",
  parse: true,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.hThree = {
  tagName: "h3",
  parse: true,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.hFour = {
  tagName: "h4",
  parse: true,
  nestable: false,
  exclusive: true,
};

document.addEventListener("trix-initialize", function (event) {
  new EmbedController(event.target);
  // new InlineCode(event.target);
});

function toolbarDefaultContent() {
  const lang = Trix.config.lang;

  return `
    <div class="mb-6 flex justify-between gap-x-6 items-start">
      <div class="flex gap-x-2 md:gap-x-3 gap-y-2 flex-wrap">
        <div class="hidden isolate inline-flex rounded-md shadow-sm z-20">
          <div class="relative" x-data="{open: false}">
            <button
              x-on:click="open = !open"
              title="Heading"
              type="button"
              class="h-10 gap-x-2 relative inline-flex items-center rounded-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            >
              <span class="sr-only">Heading</span>
              <i class="ph-bold ph-text-h-one"></i>
              <span class="text-base">Heading</span>
              <i class="ph-bold ph-caret-down text-sm"></i>
            </button>
            <div
              x-show="open"
              x-transition.origin.top.left=""
              x-on:click.outside="open = !open"
              style="display: none"
              class="absolute left-0 mt-2 w-40 rounded-md bg-white shadow-lg border border-gray-500 overflow-hidden z-20"
            >
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-xl md:text-2xl"
              >
                <i class="ph-bold ph-text-h-one w-6"></i>
                <span class="text-xl font-bold">Heading</span>
              </button>
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
              >
                <i class="ph-bold ph-text-h-two w-6"></i>
                <span class="text-lg font-medium">Subheading</span>
              </button>
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-base md:text-lg"
              >
                <i class="ph-bold ph-text-t w-6"></i>
                <span class="text-base">Normal text</span>
              </button>
            </div>
          </div>
        </div>
        <div class="isolate inline-flex rounded-md shadow-sm">
          <button
            title="Heading 1"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="hTwo"
          >
            <span class="sr-only">Heading 1</span>
            <i class="ph-bold ph-text-h-one"></i>
          </button>
          <button
            title="Heading 2"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="hThree"
          >
            <span class="sr-only">Heading 2</span>
            <i class="ph-bold ph-text-h-two"></i>
          </button>
          <button
            title="Heading 3"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="hFour"
          >
            <span class="sr-only">Heading 3</span>
            <i class="ph-bold ph-text-h-three"></i>
          </button>
        </div>
        <div class="isolate inline-flex rounded-md shadow-sm">
          <!-- Active state -->
          <button
            title="Align Left"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="alignLeft"
          >
            <span class="sr-only">Align Left</span>
            <i class="ph-bold ph-text-align-left"></i>
          </button>
          <button
            title="Align Center"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="alignCenter"
          >
            <span class="sr-only">Align Center</span>
            <i class="ph-bold ph-text-align-center"></i>
          </button>
        </div>
        <div class="isolate inline-flex rounded-md shadow-sm">
          <button
            title="Bold"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="bold"
            data-trix-key="b"
          >
            <span class="sr-only">Bold</span>
            <i class="ph-bold ph-text-b"></i>
          </button>
          <button
            title="Italic"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="italic"
            data-trix-key="i"
          >
            <span class="sr-only">Italic</span>
            <i class="ph-bold ph-text-italic"></i>
          </button>
          <button
            title="Underline"
            type="button"
            class="hidden h-10 gap-x-2 relative -ml-px inline-flex items-center bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
          >
            <span class="sr-only">Underline</span>
            <i class="ph-bold ph-text-underline"></i>
          </button>
          <button
            title="Link"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="href"
            data-trix-action="link"
            data-trix-key="k"
          >
            <span class="sr-only">Link</span>
            <i class="ph-bold ph-link"></i>
          </button>
        </div>
        <div class="hidden isolate inline-flex rounded-md shadow-sm z-20">
          <div class="relative" x-data="{open: false}">
            <button
              x-on:click="open = !open"
              title="Heading"
              type="button"
              class="h-10 gap-x-2 relative inline-flex items-center rounded-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            >
              <span class="sr-only">Text Size</span>
              <i class="ph-bold ph-text-aa"></i>
              <span class="text-base">Regular</span>
              <i class="ph-bold ph-caret-down text-sm"></i>
            </button>
            <div
              x-show="open"
              x-transition.origin.top.left=""
              x-on:click.outside="open = !open"
              style="display: none"
              class="absolute left-0 mt-2 w-40 rounded-md bg-white shadow-lg border border-gray-500 overflow-hidden z-20"
            >
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-xl md:text-2xl"
              >
                <i class="ph-bold ph-text-aa w-6"></i>
                <span class="text-xl">Larger</span>
              </button>
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
              >
                <i class="ph-bold ph-text-aa w-6"></i>
                <span class="text-lg">Regular</span>
              </button>
              <button
                class="w-full h-10 gap-x-2 relative flex items-center bg-white px-2.5 py-2.5 text-gray-800 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-base md:text-lg"
              >
                <i class="ph-bold ph-text-aa w-6"></i>
                <span class="text-base">Smaller</span>
              </button>
            </div>
          </div>
        </div>
        <div class="isolate inline-flex rounded-md shadow-sm">
          <button
            title="Numbered List"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="number"
          >
            <span class="sr-only">Numbered List</span>
            <i class="ph-bold ph-list-numbers"></i>
          </button>
          <button
            title="Bulleted List"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-attribute="bullet"
          >
            <span class="sr-only">Bulleted List</span>
            <i class="ph-bold ph-list-bullets"></i>
          </button>
        </div>
        <div class="hidden isolate inline-flex rounded-md shadow-sm">
          <button
            title="Embed Image"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
          >
            <span class="sr-only">Embed Image</span>
            <i class="ph-bold ph-image"></i>
          </button>
          <button
            title="Embed Video"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
          >
            <span class="sr-only">Embed Video</span>
            <i class="ph-bold ph-video"></i>
          </button>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="isolate inline-flex rounded-md shadow-sm">
          <!-- Disabled state -->
          <button
            disabled=""
            title="Undo"
            type="button"
            class="h-10 gap-x-2 relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-action="undo"
            data-trix-key="z"
          >
            <span class="sr-only">Undo</span>
            <i class="ph-bold ph-arrow-counter-clockwise"></i>
          </button>
          <button
            title="Redo"
            type="button"
            class="h-10 gap-x-2 relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-gray-800 ring-1 ring-inset ring-gray-500 hover:bg-gray-50 hover:text-gray-900 transition focus:z-10 text-lg md:text-xl"
            data-trix-action="redo"
            data-trix-key="shift+z"
          >
            <span class="sr-only">Redo</span>
            <i class="ph-bold ph-arrow-clockwise"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="trix-dialogs" data-trix-dialogs>
      <div
        class="trix-dialog trix-dialog--link -mt-4"
        data-trix-dialog="href"
        data-trix-dialog-attribute="href"
      >
        <div class="trix-dialog__link-fields">
          <input
            type="url"
            name="href"
            class="trix-input trix-input--dialog"
            placeholder="${lang.urlPlaceholder}"
            aria-label="${lang.url}"
            required
            data-trix-input
          />
          <div class="flex">
            <input
              type="button"
              class="rounded bg-graytw-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-graytw-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-graytw-600"
              value="${lang.link}"
              data-trix-method="setAttribute"
            />
            <input
              type="button"
              class="ml-2 text-xs font-semibold text-graytw-900"
              value="${lang.unlink}"
              data-trix-method="removeAttribute"
            />
          </div>
        </div>
      </div>
    </div>
  `;
}
