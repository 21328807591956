import { Controller } from "@hotwired/stimulus";
import { JSONEditor } from "vanilla-jsoneditor";

export default class extends Controller {
  static targets = ["container", "form", "value"];

  static values = {
    readOnly: Boolean,
    content: String,
  };

  connect() {
    const value = JSON.parse(this.contentValue);

    this.editor = new JSONEditor({
      target: this.containerTarget,
      props: {
        content: {
          json: value,
        },
        readOnly: this.readOnlyValue,
        onChange: this.onChange.bind(this),
      },
    });

    const url = new URL(window.location.href);
    const contentKey = url.searchParams.get("key");
    const defaultNodePath = contentKey?.split(".")?.slice(1) || [];

    if (defaultNodePath.length > 0) {
      this.expandKeyAndCollapseOthers(defaultNodePath);
    }
  }

  onChange(data) {
    if (data.json) {
      this.editor.update({ json: this.trimWhitespace(data.json) });
    }
  }

  trimWhitespace(obj) {
    if (typeof obj === "string") {
      return obj.trim();
    } else if (Array.isArray(obj)) {
      return obj.map((element) => this.trimWhitespace(element));
    } else if (typeof obj === "object" && obj !== null) {
      const trimmedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const trimmedKey = key.trim();
          const trimmedValue = this.trimWhitespace(obj[key]);
          trimmedObj[trimmedKey] = trimmedValue;
        }
      }
      return trimmedObj;
    } else {
      return obj;
    }
  }

  async submit(event) {
    this.valueTarget.value =
      JSON.stringify(this.editor.get().json) || this.editor.get().text;

    const form = new FormData(this.formTarget);
    try {
      const response = await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        body: form,
      });

      if (response.ok) {
        toastr.success("Saved Successfully!");
      } else {
        toastr.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error submitting JSON data", error);
    }
  }

  expandKeyAndCollapseOthers(defaultNodePath) {
    this.editor.expand((path) => {
      if (
        path.length === 0 ||
        this.isPrefixOrExactMatch(path, defaultNodePath)
      ) {
        return true;
      }

      return false;
    });
  }

  isPrefixOrExactMatch(path, defaultNodePath) {
    if (path.length < defaultNodePath.length) {
      return path.every((value, index) => value === defaultNodePath[index]);
    } else {
      return defaultNodePath.every((value, index) => value === path[index]);
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
