import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entries", "pagination", "loading"];

  initialize() {
    const options = {
      rootMargin: "200px",
    };

    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      options
    );

    this.fetching = false;
  }

  connect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.observe(this.paginationTarget);
      this.hidePagination();
    }
  }

  disconnect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget);
    }
  }

  hidePagination() {
    if (this.hasPaginationTarget) {
      let pagination = this.paginationTarget.querySelector(".pagy-nav");
      pagination?.classList?.remove("pagy-nav", "pagination");
      pagination?.classList?.add("hidden");
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    if (this.fetching) {
      return;
    }

    this.fetching = true;
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (next_page == null) {
      return;
    }
    let url = next_page.href;

    this.showLoading();

    fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries);
        this.paginationTarget.innerHTML = data.pagination;

        this.hideLoading();
        this.fetching = false;

        if (data.pagination) {
          this.hidePagination();
        } else {
          this.intersectionObserver.unobserve(this.paginationTarget);
        }
      })
      .catch((error) => {
        console.error("Error fetching more entries:", error);
        this.hideLoading();
        this.fetching = false;
      });
  }

  showLoading() {
    this.loadingTarget.classList.remove("hidden");
  }

  hideLoading() {
    this.loadingTarget.classList.add("hidden");
  }
}
