import { Controller } from "@hotwired/stimulus";
import "select2";

// Connects to data-controller="profile-select2"
export default class extends Controller {
  static targets = ["select"];

  connect() {
    if (!window.$) {
      setTimeout(() => this.connect(), 100);
      return;
    }
    const $ = window.$;

    $(this.selectTarget).select2({
      // minimumResultsForSearch: Infinity,
      templateResult: this.formatFounder,
      dropdownCssClass: "js-founder-select-dropdown",
    });

    $(this.selectTarget).on("change", this.updateFounder.bind(this));
  }

  formatFounder(state) {
    if (!state.id) {
      return state.text;
    }

    let founderImage = $(state.element).data("founder-image");
    let founderDescription = $(state.element).data("founder-description");

    return $(
      `<span class="flex items-start"><img src="${founderImage}" class="w-9 sm:w-10 h-9 sm:h-10 rounded-full mr-2.5 sm:mr-3 bg-gray-900/20 shrink-0 object-cover" /> <span class="self-center"><span class="js-select2-founder-name text-sm sm:text-base font-semibold block">${state.text}</span><span class="js-select2-founder-description text-xs sm:text-sm line-clamp-3 sm:line-clamp-2">${founderDescription}</span></span></span>`
    );
  }

  updateFounder() {
    let currentSelection = $(this.selectTarget).select2("data");
    this.element.dataset.founder = currentSelection[0].id;
  }

  disconnect() {
    $(this.selectTarget).select2("destroy");
  }
}
