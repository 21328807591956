import { Controller } from "@hotwired/stimulus";
import ClipboardJS from "clipboard";
import tippy from "tippy.js";

// Connects to data-controller="copyable"
export default class extends Controller {
  static targets = ["trigger", "content"];

  static values = {
    successMessage: String,
    errorMessage: String,
  };

  connect() {
    this.triggerTarget.addEventListener("click", this.copy.bind(this));
  }

  async copy(ev) {
    ev.preventDefault();

    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([this.contentTarget.innerHTML], {
        type: "text/html",
      }),
      "text/plain": new Blob([this.contentTarget.textContent], {
        type: "text/plain",
      }),
    });

    try {
      await navigator.clipboard.write([clipboardItem]);
      this.tooltip(this.successMessage);
    } catch (err) {
      this.tooltip(this.errorMessage);
    }
  }

  tooltip(message) {
    if (!this.tooltipInstance) {
      this.tooltipInstance = tippy(this.triggerTarget, {
        content: message,
        showOnCreate: true,
        onHidden: (instance) => {
          instance.destroy();
          this.tooltipInstance = null;
        },
      });
    }
  }

  get successMessage() {
    return this.successMessageValue || "Copied!";
  }

  get errorMessage() {
    return this.errorMessageValue || "Copy Failed!";
  }
}
