// Example usage:
// <form data-controller="auto-submit">
//

import { Controller } from "@hotwired/stimulus";
let debounce = require("lodash.debounce");

export default class extends Controller {
  connect() {
    this.children = this.element.querySelectorAll("input");
    this.children.forEach((input) => {
      input.addEventListener("input", this.submit.bind(this));
    });
  }

  disconnect() {
    this.children.forEach((input) => {
      input.removeEventListener("input", this.submit.bind(this));
    });
  }

  submit() {
    debounce(() => {
      this.element.requestSubmit();
    }, 100)();
  }
}
