import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image-upload-preview"
export default class extends Controller {
  static targets = ["input", "preview"];
  static values = {
    originalUrl: String,
  };

  connect() {
    this.emptyClassNames = ["border", "border-dashed", "border-graytw-900/25"];
    this.previewClassNames = ["bg-cover", "bg-center", "bg-no-repeat"];
    this.inputTarget.addEventListener("change", this.preview.bind(this));

    this.setPreview(this.originalUrlValue);
  }

  preview() {
    let input = this.inputTarget;
    let preview = this.previewTarget;
    let file;
    if (input.files) file = input.files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      preview.style.backgroundImage = `url(${reader.result})`;
      this.setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.clearPreview();
    }

    input.blur();
  }

  setPreview(bgImage) {
    if (bgImage) bgImage = bgImage.trim();
    if (!bgImage) {
      this.clearPreview();
      return;
    }

    this.previewTarget.classList.remove(...this.emptyClassNames);
    this.previewTarget.classList.add(...this.previewClassNames);
    this.previewTarget.style.backgroundImage = `url(${bgImage})`;
  }

  clearPreview() {
    this.previewTarget.classList.remove(...this.previewClassNames);
    this.previewTarget.classList.add(...this.emptyClassNames);
    this.previewTarget.style.backgroundImage = "none";
  }
}
