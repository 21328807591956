document.addEventListener("turbo:submit-start", function () {
  const progressBar = Turbo.navigator.delegate.adapter.progressBar;
  progressBar.setValue(0);
  progressBar.show();
});

document.addEventListener("turbo:submit-end", function () {
  const progressBar = Turbo.navigator.delegate.adapter.progressBar;
  progressBar.setValue(100);
  progressBar.hide();
});
